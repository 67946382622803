import Image, { ImageProps } from 'next/image'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'

import BrickLogo from '../../../../public/assets/common/brick-logo-filled.svg'
import facebookImage from '../../../../public/assets/Social/facebook.png'
import instagramImage from '../../../../public/assets/Social/instagram.png'
import linkedinImage from '../../../../public/assets/Social/linkedin.png'
import { Links } from '../../../lib/constants'
import {
  NavigationItem,
  useNavigationLinksFooter,
} from '../../../lib/hooks/useNavigationLinks'
import GetInTouch from '../GetInTouch/GetInTouch'
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown'
import * as S from './styled'

type SocialLinks = {
  imageProps: ImageProps
  link: string
}

type Props = {}

const Footer: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  const navItems = useNavigationLinksFooter()

  const socialLinks: SocialLinks[] = [
    {
      imageProps: { src: instagramImage, alt: t('footer.instagram_alt') },
      link: Links.INSTAGRAM,
    },
    {
      imageProps: { src: facebookImage, alt: t('footer.facebook_alt') },
      link: Links.FACEBOOK,
    },
    {
      imageProps: { src: linkedinImage, alt: t('footer.linkedin_alt') },
      link: Links.LINKEDIN,
    },
  ]

  const renderLinkColumn = (links: NavigationItem[]) => {
    return links.map(link => (
      <S.LinksWrapper key={link.label}>
        <S.NavLink href={link.path} prefetch={false}>
          {link.label}
        </S.NavLink>
        {link.menuItems.map(menuItem =>
          menuItem.external ? (
            <S.ExternalLink
              href={menuItem.path}
              key={menuItem.label}
              target="_blank"
              rel="noreferrer"
            >
              {menuItem.label}
            </S.ExternalLink>
          ) : (
            <S.SubNavLink
              href={menuItem.path}
              key={menuItem.label}
              prefetch={false}
            >
              {menuItem.label}
            </S.SubNavLink>
          )
        )}
      </S.LinksWrapper>
    ))
  }

  return (
    <S.FooterContainer>
      <S.FooterWrapper>
        <GetInTouch />
        <S.DividerContainer>
          <S.DividerLeft />
          <Image
            src={BrickLogo}
            width={92}
            height={46}
            alt="company logo Brick"
          />
          <S.DividerRight />
        </S.DividerContainer>

        <S.LinksContainer>{renderLinkColumn(navItems)}</S.LinksContainer>

        <S.BottomContainer>
          <S.CopyrightText>
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </S.CopyrightText>
          <LanguageDropdown reverse />
          <S.SocialContainer>
            {socialLinks.map(social => (
              <S.SocialLink
                href={social.link}
                target="_blank"
                rel="noreferrer"
                key={social.link}
              >
                <S.SocialImage
                  src={social.imageProps.src}
                  alt={social.imageProps.alt}
                  height={24}
                  width={24}
                />
              </S.SocialLink>
            ))}
          </S.SocialContainer>
        </S.BottomContainer>
      </S.FooterWrapper>
    </S.FooterContainer>
  )
}

export default Footer
